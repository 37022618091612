.no-border-radius {
  border-radius: 0 !important;
}

.none-scroll-bar {
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
    background: transparent; /* make scrollbar transparent */
  }
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  //background: transparent;
  width: 6px;
}

*::-webkit-scrollbar-track {
  //background: transparent;
  //background: black;
  //display: none;
}

*::-webkit-scrollbar-thumb {
  position: absolute;
  background-color: #DAE1E7;
  width:14px;
  border-radius: 14px;
  border: 3px solid var(--primary);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #4B566B;
}

// PDATA 이미지
.pdata-image-wrapper {
  width: 100%;
  height: 100%;
  position: relative;

  > img.pdata-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

// 와인샵 이미지
.vendor-image-box,
.vendor-image-wrapper {
  overflow: hidden;
  border-radius: 10px;
  img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;

    image-rendering: optimizeSpeed; /* STOP SMOOTHING, GIVE ME SPEED */
    // image-rendering: optimizeQuality; /* Universal support since 2021 */
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Chrome (and eventually Safari) */
    image-rendering: optimize-contrast; /* CSS3 Proposed */

    /* drag 막기 */
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
}

// marked-text
.marked-text {
  position: relative;
  z-index: 1;
  .marked-text__label {
    padding: 0 4px;
    display: inline-block;
  }
  .marked-text__bg {
    background-color: #defc99;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 13px;
    z-index: -1;
  }
}
